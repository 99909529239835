

.h1 {
  color: white;
}

.h2 {
  color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 32px;
  letter-spacing: 5px;
}

.container .unityWrapper {
  background: #000000;
  width: 800px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Change to column layout */
}

.container .unityWrapper .loadingBar {
  background: #111111; 
  width: 500px;
  height: 5px;
  border-radius: 1px;
  overflow: hidden;
}

.container .unityWrapper .loadingBar .loadingBarFill {
  border-radius: 1px;
  /* margin: 2px; */
  height: 5px;
  background: #ffffff;
  transition: 0.3s width ease-out;
}

.container .unityWrapper .loadingText {
  overflow: hidden;
  margin-top: 10px; /* Add top margin to separate the text and loading bar */
  text-align: center; /* Center-align text horizontally */
}

.container .unityWrapper .loadingText p{
  color: #E31031;
}

.container .unityWrapper canvas {
  width: 100%;
  height: 100%;
}

.container .screenshots {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

body {
  background: #040b10;
}

.table,
p {
  color: #fff;
}
ul {
  list-style: none;
  padding: 0;
}

.navbar-dark {
  background: #081824 !important;
}

.nav-item {
  padding: 13px 9px;
}

a.nav-link {
  text-transform: uppercase;
}

.nav-link:hover {
  background: #0b2231;
  border-radius: 8px;
  color: #9ac9e3;
}

.btn-mry,
.btn-mry:hover {
  border: 1px solid rgb(11 34 49/1);
  border-radius: 0.5rem;
  background: #040b10;
  color: #fff;
  padding: 10px 18px;
}
.text-error {
  margin-top: 4px;
  color: red;
}

.mry-card {
  background: #0b2231;
  color: #fff;
}

.img-thumbnail {
  padding: 0;
  background-color: none;
  border: none;
  border-radius: 0;
}

.card-link {
  color: #9ac9e3;
}

.card-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.cursor-pointer{
  cursor: pointer;
}
.bg-base-4{
  background-color: rgb(11, 34, 49);
}
.text-base-10{
  color: #E7EBEE;
}

.border-none {
  border: none;
}

.list-form {
  background: #081824;
  border-radius: 10px;
  margin-right: 10px;
}

input.form-control,
input.form-control:focus {
  background: #040b10;
  color: #fff;
  padding: 10px 18px;
  border: 1px solid rgb(11 34 49/1);
}

.Toastify .Toastify__toast {
  background-color: #081824;
  border-radius: 12px;
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.3);
}

.Toastify .Toastify__toast-body {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.Toastify .Toastify__toast--default {
  color: #ecf4fb;
}

.Toastify .Toastify__toast--warning {
  color: #ffe318;
}

.Toastify .Toastify__toast--success {
  color: #52ae6c;
}

.Toastify .toast-success-buy-ticket {
  margin-top: 100px;
  color: #5b5b7b;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  background-color: #ffffff;
}

.Toastify .Toastify__toast--info {
  color: #ecf4fb;
}

.Toastify .Toastify__toast--error {
  color: #c62828;
}

.Toastify .Toastify__close-button {
  color: white;
  align-self: center;
}
.Toastify .Toastify__close-button svg {
  height: 18px;
  width: 18px;
}

.toogleSwitchSelect {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}
.toogleSwitchSelect input {
  opacity: 0;
  width: 0;
  height: 0;
}
.toogleSwitchSelect span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2c3e50;
  transition: 0.3s;
  border-radius: 30px;
}

.toogleSwitchSelect span:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.toogleSwitchSelect input:checked + span {
  background-color: #00c853;
}
.toogleSwitchSelect input:checked + span:before {
  transform: translateX(29px);
}
.toogleSwitchSelect strong {
  position: absolute;
  right: calc(100% + 16px);
  width: max-content;
  line-height: 30px;
  margin-left: 10px;
  cursor: pointer;
  color: #fff;
}
.form-check-input {
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  border-color: #ffa500;
}
.form-check-input:checked {
  background-color: #ffa500;
  border-color: #ffa500;
}